import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { PasswordInput, Input, SearchSelect } from "../../components/Inputs";
import {
  authRequest,
  clearAll,
  loginRequest,
  socialLogin,
  socialLoginList
} from "../../state/actions/login-actions";
import AuthWrapper from "./AuthWrapper";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { APPLE_CLIENT_URL, FB_CLIENT_URL, REDIRECT_URL } from "../../constants";
import { Facebook } from "react-feather";
import AppleLogin from "./AppleLogin";
import WlinkLogo from "../../assets/image/wlink.png";
import { Config } from "../../Config";
import Modal from "../../components/Modal";
import WlinkLogin from "./WlinkLogin";
 
const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setemail] = useState("");
  const [wlinkOpen, setWlinkOpen] = useState(false);
  const [socialLoginOptions,setSocialLoginOption] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socialLoginList()).then((res)=>{
      if(res?.status === 200){
        setSocialLoginOption(res?.data?.data)
      }
    })
    const tenant_id = localStorage.getItem("tenant_id");
    if (!tenant_id) {
      localStorage.setItem("tenant_id", "");
    }
  }, []);



  const { error, loading } = useSelector((state) => ({
    error: state.login?.error?.data,
    loading: state.login?.processing,
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      email: email,
      password: password,
    };

    if (email === "" || password === "") {
      toast.error("Please enter the required fields", { theme: "colored" });
    } else {
      dispatch(authRequest(data, navigate));
    }
  };

  const handleCaptcha = (value) => {
    setCaptcha(value);
  };

  const handleGoogleLogin = (credentials) => {
    const saveData = {
      login_type: "google",
      token: credentials?.credential,
    };
    dispatch(socialLogin(saveData, navigate));
  };

  const handleFacebookLogin = (credentials) => {
    const saveData = {
      login_type: "facebook",
      token: credentials?.code,
    };
    dispatch(socialLogin(saveData, navigate));
  };

  const handleAppleLogin = (credentials) => {
    const saveData = {
      login_type: "apple",
      token: credentials?.authorization?.id_token,
    };
    console.log('saveData',saveData)
    dispatch(socialLogin(saveData, navigate));
  };

  const handleWorldLinkLogin = () =>{
    axios.get(`${Config.AuthUrl}/third-party/worldlink-login/v1/worldlink/getCredentials`).then((res,err)=>{
      console.log("@res ",res)
      if(res?.status === 200){
        try {
          let redirect_url =`${Config.WlinkRedirect}/worldlink_oath`;
          let params ="scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=400,height=600,left=0,top=0";
          const uri = `${Config.WlinkOauthAuthUrl}/authorize?client_id=${res?.data?.client_id}&redirect_uri=${redirect_url}&response_type=code&scope=openid&state=login`
          window.open(
            uri,
            "_self",
            params
          )
        } catch (e) {
          console.log("Error: could not load Worldlink due to data mismatch", e);
        }
      }if(res.status === 422){
        console.log("err")
      }
    })

   
  }

  const AppleButton = () => {
    return <p>Apple</p>;
  };

  return (
    <AuthWrapper>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-[#1a374d] md:text-2xl">
          Sign in to your account
        </h1>
        <form onSubmit={onSubmit} className="space-y-4 md:space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <Input
              value={email}
              handleChange={(e) => {
                dispatch(clearAll());
                setemail(e.target.value);
              }}
              placeholder="email"
              error={error?.email?.[0]}
              autoComplete="on"
              data-testid="email"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Password
            </label>
            <PasswordInput
              value={password}
              handleChange={(e) => {
                dispatch(clearAll());
                setPassword(e.target.value);
              }}
              error={error?.password?.[0]?.password}
              placeholder="password"
            />
          </div>
          {/* <div className="flex justify-center">
            <ReCAPTCHA
              sitekey="Your client site key"
              onChange={handleCaptcha}
            />
          </div> */}

          {typeof error !== "object" && (
            <small className="text-error mt-1 text-xs">{error}</small>
          )}
          <div className="flex items-center justify-center">
            <button>
              <Link
                className="text-sm font-medium text-blue-600 hover:underline"
                to="/forgot-password"
              >
                Forgot password?
              </Link>
            </button>
          </div>
          <button
            type="button"
            onClick={(e) => onSubmit(e)}
            className="w-full text-white bg-primary hover:bg-[#2d597a] hover:transition-all focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:bg-[#344f64]"
            data-testid="login"
          >
            {loading ? "Logging In..." : "Login"}
          </button>
        </form>
        <div className="mt-2 space-y-2">
          {
            socialLoginOptions?.length >0 &&
          <p className="flex  justify-center text-xs">Or Login with</p>

          }
          <div className="flex justify-center gap-2">

          {
            socialLoginOptions && socialLoginOptions?.map((item,i)=>{
              if(item?.name.toLowerCase() === "google" && item?.status === "1"){
                return (
                  <GoogleLogin
                  shape="circle"
                  type="icon"
                  logo_alignment="center"
                  onSuccess={(credentialResponse) => {
                    handleGoogleLogin(credentialResponse);
                  }}
                  onError={(error) => {
                    console.log("Login Failed",error);
                  }}
                  auto_select={false}
                />
                )

              }


              if(item?.name.toLowerCase() === "facebook" && item?.status === "1"){
                return (
                  <FacebookLogin
                  appId={FB_CLIENT_URL}
                  autoLoad={false}
                  size="small"
                  textButton=""
                  fields={"name,email,picture"}
                  callback={(response) => {
                    handleFacebookLogin(response);
                  }}
                  onFailure={(err) => {
                    console.log("something went wrong!", err);
                  }}
                  redirectUri="https://www.facebook.com/"
                  icon={
                    <p className="w-6 h-6 bg-blue-600 rounded-full flex justify-center align-middle items-center text-white ">
                      <Facebook size={18} fill="#ffffff" strokeWidth={0.3} />
                    </p>
                  }
                  cssClass={
                    "rounded-full bg-white border border-gray-300 border-opacity-80  w-10 h-10 text-sm flex justify-center items-center  hover:bg-blue-50 hover:border-blue-200"
                  }
                />
                )
              }

              if(item?.name.toLowerCase() === "worldlink" && item?.status === "1"){
                return (
                  <div className="rounded-full bg-white border border-gray-300 border-opacity-80  w-10 h-10 text-sm flex justify-center items-center  hover:bg-blue-50 hover:border-blue-200">
                  <button
                    onClick={() => handleWorldLinkLogin()}
                  >
                    <img src={WlinkLogo} className="w-6 h-6" />
                  </button>
                </div>
                )
              }

              if(item?.name.toLowerCase() === "apple" && item?.status === "1"){
                return (

                  <div className="rounded-full bg-white border border-gray-300 border-opacity-80  w-10 h-10 text-sm flex justify-center cursor-pointer items-center  hover:bg-blue-50 hover:border-blue-200">
                  <AppleLogin
                    clientId={APPLE_CLIENT_URL}
                    redirectURI={`${REDIRECT_URL}`}
                    autoLoad={false}
                    render={<AppleButton />}
                    callback={(res) => {
                      handleAppleLogin(res);
                    }}
                    usePopup
                  />
                </div>
                )
              }




            })
          }

          </div>
        </div>
      </div>
      <Modal
        open={wlinkOpen}
        title={"Login with Wlink"}
        onClose={() => {
          setWlinkOpen(false);
        }}
        body={<WlinkLogin />}
      />
    </AuthWrapper>
  );
};

export default Login;
