import React, { useState } from 'react'
import LoadingDiv from '../../components/LoadingDiv'
import Button from '../../components/Button'
import { activeTrailPackage } from '../../state/actions/trial-package-actions'
import { useDispatch, useSelector } from 'react-redux'
import { successAlert } from '../../state/actions/alert-actions'

const TrailPackage = ({trailPackage, inventorySerial, selectedCamera}) => {

    // const [trailPackage,setTrailPackage] = useState();
    const [loading,setLoading] = useState(false)
      const { profileData, loginUser } = useSelector((state) => ({
        profileData: state?.profile?.profileData,
        loginUser: state?.profile?.profileData?.subscriber_details
      }));
console.log('loginUser',loginUser)
const dispatch = useDispatch()
const [activateLoader,setActivateLoader] = useState(false)
function activatePackage(packageId,planId){
  setActivateLoader(true)
  let data ={
    "package_id": packageId,
    "package_plan_id": planId,
    "payment_gateway": "cash",
    // "payment_method": "cash",
    "qty": 1,
    "subscriber_id": loginUser?.subscriber_id,
    "created_by": `${profileData?.username}`,
    "subscriber_ipcam_id": 1 * selectedCamera?.id
}
dispatch(activeTrailPackage(data)).then((res)=>{
  if(res.status === 200){
    dispatch(successAlert("Your trail package has been activated Successfully ."))
      setLoading(false)
      setActivateLoader(false)
      window.location.href = `/cameras/subscription/${selectedCamera?.id}?inventory-serial=${inventorySerial}`
  }else{
    setActivateLoader(false)
  }
})
}

    return (
        <>
            <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                <div className="flex justify-between">
                    <p className="font-medium flex gap-x-2 items-start">
                        {trailPackage?.name}
                    </p>
                    <div>
                        {<Button handleClick={() => activatePackage(trailPackage?.id, trailPackage?.plans[0]?.id)} isDisabled={activateLoader}>
                            {activateLoader ? "Activating" : "Activate Package"}
                        </Button>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrailPackage