export function activeCheckDate(dateStr) {
    const targetDate = new Date(dateStr);
    const currentDate = new Date();
    if (targetDate > currentDate) {
        return true;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
        return true;
    } else {
        return false;
    }
}