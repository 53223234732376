import { useDispatch, useSelector } from "react-redux";
import LoadingDiv from "../../components/LoadingDiv";
import { getCameras, getPackages, getSubscriptions } from "../../state/actions/cameras-actions";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { activeCheckDate } from "../../utils/commonHelperFunctions";
import { calculateRemainingDays } from "../../utils/SubscriptionDateHelpers";
import NewPackage from "./NewPackage";
import Modal from "../../components/Modal";
import { getTrialPackage } from "../../state/actions/trial-package-actions";
import TrailPackage from "./TrailPackage";

const MyCameras = () => {
  const initialState = {
    selectedCamera: {},
    newOpen: false,
    trailPackage: {},
    inventorySerial: '',
    loaders: {
      trailPackageLoading: false
    }
  }
  
  const { profileData, loading, cameraData, loginUser } = useSelector((state) => ({
    profileData: state?.profile?.profileData,
    loading: state?.cameras?.loaders?.cameras,
    cameraData: state?.cameras?.cameraData,
    loginUser: state?.profile?.profileData?.subscriber_details
  }));
  
  const dispatch = useDispatch();
  const [state, setState] = useState({...initialState})
  const {selectedCamera, newOpen, trailPackage, inventorySerial, loaders} = state;
  const [filterStatus, setFilterStatus] = useState('all');
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (profileData?.id) {
      dispatch(getCameras({ id: profileData?.id, limit: 2000, sort_field:'created_at',sort_by:'desc' }));
    }
  }, [profileData]);

  const changeHandler = (e) => {
    setQuery(e.target.value)
  }

  const hasActiveSubscription = (camera) => {
    return camera?.subscription?.some(sub => 
      sub?.status*1 === 1 && activeCheckDate(sub?.expiry_date)
    );
  };

  const has5DaysLeft = (camera) => {
    return camera?.subscription?.some(sub => {
      if (sub?.status*1 === 1 && activeCheckDate(sub?.expiry_date)) {
        const daysLeft = calculateRemainingDays(sub?.expiry_date);
        return daysLeft <= 5 && daysLeft > 0;
      }
      return false;
    });
  };

  const filteredBySearch = cameraData?.data?.filter((item) => 
    item?.inventory_serial?.toLowerCase()?.includes(query?.toLowerCase()) || 
    item?.custom_label?.toLowerCase()?.includes(query?.toLowerCase())
  );

  const filteredByStatus = filteredBySearch?.filter(camera => {
    switch(filterStatus) {
      case 'active':
        return hasActiveSubscription(camera);
      case 'expired':
        return !hasActiveSubscription(camera);
      case '5days':
        return has5DaysLeft(camera);
      default:
        return true;
    }
  });

  const navigate = useNavigate()
  const handleNavigation = (each) => {
    navigate({
      pathname: `/cameras/subscription/${each?.id}`,
      search: `?inventory-serial=${encodeURIComponent(each?.inventory_serial)}&custom-label=${encodeURIComponent(each?.custom_label ? each.custom_label : each?.ipcam_label)}`
    });
  };

  const handleBuyPackage = (each) => {
    setState((state)=>({
      ...state,
      selectedCamera:{...each},
      inventorySerial:each?.inventory_serial,
      newOpen:true,
      loaders:{...state?.loaders,trailPackageLoading:true}
    }))

    setTimeout(()=>{
      if(loginUser?.subscriber_id && each?.inventory_serial){
        dispatch(getTrialPackage({subscriberId:loginUser?.subscriber_id,inventorySerial:each?.inventory_serial})).then((res)=>{
          if(!!res && res.status === 200){
            setState((state)=>({
              ...state,
              trailPackage:res?.data,
              loaders:{...initialState?.loaders}
            }))
          } else {
            setState((state)=>({
              ...state,
              loaders:{...initialState?.loaders}
            }))
          }
        },(err)=>{
          setState((state)=>({
            ...state,
            loaders:{...initialState?.loaders}
          }))
        })
      }
    },0)
  }

  function handeCloseModal(){
    setState({...initialState})
  }

  return (
    <ContentWrapper placeholder={'Search Camera'} searchInput={true} changeHandler={changeHandler} searchClassName='w-full'>
      <div className="relative mb-10">
        <div className="mb-4 text-end">
          <select 
            value={filterStatus} 
            onChange={(e) => setFilterStatus(e.target.value)}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          >
            <option value="all">All Cameras</option>
            <option value="active">Active Subscriptions</option>
            <option value="expired">Expired Subscriptions</option>
            <option value="5days">Less than 5 Days</option>
          </select>
        </div>
        
        <div className="flex flex-col gap-4">
          {loading ? (
            <LoadingDiv />
          ) : filteredByStatus?.length > 0 ? (
            <>
              {filteredByStatus?.map((each, idx) => (
                <div key={idx}>
                  <div className="w-full rounded-xl shadow p-4 bg-white border-gray-200">
                    <div className="flex justify-between items-start gap-2">
                      <div>
                        <p className="font-normal md:font-medium text-sm md:text-base cursor-pointer hover:text-blue-600" 
                           title="Click to show details" 
                           onClick={() => handleNavigation(each)}>
                          <span className="mr-2">{each?.inventory_serial}</span>
                          {each?.custom_label && <span>({each.custom_label})</span>}
                        </p>
                        <p className="text-gray-500 text-sm font-base">
                          {moment(each?.created_at).format("lll")}
                        </p>
                      </div>
                      <div>
                        <Button handleClick={() => handleBuyPackage(each)}>
                          Buy Package
                        </Button>
                      </div>
                    </div>

                    <div className="mt-2">
                      {each?.subscription?.some(sub => sub?.status*1 === 1 && activeCheckDate(sub?.expiry_date)) ? (
                        <div>
                          {each?.subscription?.filter(sub => sub?.status === 1 && activeCheckDate(sub?.expiry_date))?.slice(0, 1)?.map(sub => (
                            <div key={sub?.order_id}>
                              <div className="flex justify-between gap-2">
                                <div>
                                  <p className="font-normal md:font-medium text-xs md:text-sm">
                                    <span>{sub?.packages?.name}</span>
                                  </p>
                                  <p className="font-normal text-sm">
                                   <span className={`${calculateRemainingDays(sub?.expiry_date) <= 5 ? 'text-red-500' : 'text-green-500'} text-xs md:text-sm font-normal md:font-medium`}>
                                     {calculateRemainingDays(sub?.expiry_date)} Days
                                   </span> 
                                   <span className="text-sm text-gray-500"> Remaining</span>  
                                  </p>
                                </div>
                                <div>
                                  <p title={`Start Date: ${moment(sub?.start_date).format("LL")}`}>
                                    <span className="text-gray-400 text-xs md:text-sm font-normal md:font-medium mr-1">
                                      Start on: 
                                    </span>
                                    <span className="text-xs md:text-sm font-normal md:font-medium">
                                      {moment(sub?.start_date).format("MMM D, YYYY")}
                                    </span>
                                  </p>
                                  <p title={`Expires Date: ${moment(sub?.expiry_date).format("LL")}`}>
                                    <span className="text-gray-400 text-xs md:text-sm font-normal md:font-medium mr-1">
                                      Expire on: 
                                    </span>
                                    <span className="text-xs md:text-sm font-normal md:font-medium">
                                      {moment(sub?.expiry_date).format("MMM D, YYYY")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-sm font-normal text-gray-500">No active subscriptions</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div>No Cameras Found</div>
          )}
        </div>
      </div>
      <Modal
        title={trailPackage?.id ? 'Activate your trial Package' : 'Buy new Package'}
        open={newOpen}
        onClose={handeCloseModal}
        body={loaders?.trailPackageLoading ? <LoadingDiv/> :
          trailPackage?.id ? (
            <TrailPackage trailPackage={trailPackage} inventorySerial={inventorySerial} selectedCamera={selectedCamera}/>
          ) :
          <NewPackage
            camera_id={selectedCamera?.id}
            handleClose={handeCloseModal}
          />
        }
        className={"xs:w-full md:w-3/5"}
      />
    </ContentWrapper>
  );
};

export default MyCameras;