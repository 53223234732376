import { ArrowLeft, Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PermissionsHelper } from "../utils/PermissionsHelper";
import { useEffect } from "react";
import { setPageLoading } from "../state/actions/profile-actions";

const ContentWrapper = ({
  title,
  backBtn,
  children,
  showAdd,
  addRoute,
  addText,
  extraButton,
  extraRoute,
  extraText,
  addClick,
  bodyClass,
  noWrapper,
  nonLinkButton,
  searchInput,placeholder,changeHandler,searchClassName
}) => {
  /**
   *
   * @param  {String} title // Title of content
   * @param {boolean} showAdd // to show button or not
   * @param  {String} addRoute // route for add button
   * @param {ReactDOM} children // render content inside wrapper
   * @param  {String} addText // text to show in button
   *
   *
   */
  const { permissions, ticketPermissions, crmPermissions } = useSelector(
    (state) => ({
      permissions: state?.profile?.profileData?.role?.permissions,
      ticketPermissions: state?.profile?.profileData?.role?.ticketing || [],
      crmPermissions: state?.profile?.profileData?.role?.crm || [],
    })
  );
  const dispatch = useDispatch();

  const location = useLocation();
  const path = location.pathname.replace("/", "");

  useEffect(() => {
    dispatch(setPageLoading(true));

    const getData = setTimeout(() => {
      dispatch(setPageLoading(false));
    }, 250);

    return () => clearTimeout(getData);
  }, []);

  // bg-gradient-to-br from-slate-200 via-slate-400 to-slate-600
  const navigate = useNavigate()
  return (
    <div className={`relative ${noWrapper ? "" : ``} `}>
      <div className={`flex justify-between mt-2 p-4 sticky top-0 bg-[#F9FAFB] z-10`}>
       
          <div className={`flex justify-${title ? 'between' : 'start'} items-center w-full gap-4`}>
              {(title || backBtn) && <p className="heading w-full flex justify-start items-center gap-4"> 
                <span className="cursor-pointer" onClick={()=>navigate(-1)}>{<ArrowLeft size={20}/>}</span> 
                  <span>{title}</span>
                </p>}
              {searchInput && 
                <span className={`${searchClassName && searchClassName}`}>
                  <input type="search" placeholder={placeholder} className="w-full border-1.5 rounded-xl shadow px-4 border-gray-300" onChange={changeHandler}/>
                </span>}
          </div>
        
        <div className="flex gap-3">
          {extraButton ? (
            <Link
              className=" bg-green-500 hover:bg-green-700 hover:text-white text-sm text-white font-bold py-1.5 px-3 rounded-md flex flex-wrap justify-center align-middle"
              to={extraRoute ? extraRoute : "#"}
              onClick={addClick}
              data-testid="link"
            >
              {extraText ? extraText : "Add New"}
            </Link>
          ) : (
            ""
          )}
          {showAdd &&
          PermissionsHelper(
            path,
            [...permissions, ...ticketPermissions, ...crmPermissions],
            "store"
          ) ? (
            <Link
              className=" bg-blue-500 hover:bg-blue-700 hover:text-white text-sm text-white font-bold py-1.5 px-3 rounded-md flex flex-wrap justify-center align-middle"
              to={addRoute ? addRoute : "#"}
              onClick={addClick}
              data-testid="link"
            >
              <Plus size={15} strokeWidth={4} className="mt-1 mr-2" />{" "}
              {addText ? addText : "Add New"}
            </Link>
          ) : (
            ""
          )}
          {nonLinkButton ? (
            <button
              className=" bg-blue-500 hover:bg-blue-700 hover:text-white text-sm text-white font-bold py-1.5 px-3 rounded-md flex flex-wrap justify-center align-middle"
              onClick={addClick}
              data-testid="link"
            >
              <Plus size={15} strokeWidth={4} className="mt-1 mr-2" />{" "}
              {addText ? addText : "Add New"}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={bodyClass ? bodyClass : "mt-3 p-4"} data-testid="child">
        {children}
      </div>
    </div>
  );
};

export default ContentWrapper;
