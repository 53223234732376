import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AtSign, Bookmark, Info, Mail, MapPin, Phone, Settings, User } from "react-feather";
import { getCameras } from "../../state/actions/cameras-actions";
import LoadingDiv from "../../components/LoadingDiv";
import Button from "../../components/Button";
import { Input } from "../../components/Inputs";
import SelectInput from "../../components/SelectInput";
import { localValidation } from "../../helpers/ValidationHelpoer";
import { scrollToPositionFrom } from "../../helpers/AnimationHelpers";
import InputBasic from "../../components/InputBasic";
import "./css/popup.css";
import UploadImage from "../../assets/image/Upload.png";
import editicon from "../../assets/image/editicon.png";
import axios from "axios";
import { Config } from "../../Config";
import { loginToken } from "../../helpers/GeneralHelpers";
import {
  EditSubscribersKyc,
  createSubscribersKyc,
  getSubscriberKyc,
} from "../../state/actions/subscriberskyc-actions";
import { useNavigate, useParams } from "react-router-dom";
import { errorAlert, successAlert } from "../../state/actions/alert-actions";
import { editProfile } from "../../state/actions/profile-actions";
import { getProfileData } from "../../state/actions/profile-actions";
import getCountries from "../../state/actions/countries-get";
import NotFoundPage from "../NotFoundPage";
let genderLists = [
  { label: "Please Select", value: "" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "others" },
];
let nationality = [
  { label: "Please Select", value: "" },
  { label: "Nepal", value: "Nepal" },
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Armenia", value: "Armenia" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Brunei", value: "Brunei" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "China", value: "China" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Georgia", value: "Georgia" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran", value: "Iran" },
  { label: "Iraq", value: "Iraq" },
  { label: "Israel", value: "Israel" },
  { label: "Japan", value: "Japan" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Laos", value: "Laos" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "North Korea", value: "North Korea" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palestine", value: "Palestine" },
  { label: "Philippines", value: "Philippines" },
  { label: "Qatar", value: "Qatar" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Singapore", value: "Singapore" },
  { label: "South Korea", value: "South Korea" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Syria", value: "Syria" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "UAE", value: "United Arab Emirates" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Yemen", value: "Yemen" },
];

let maritalstatus = [
  { label: "Please Select ", value: "" },
  { label: "Married", value: "married" },
  { label: "Single", value: "single" },
  { label: "Others", value: "others" },
];
const documentoption = [
  { label: "Please Select ", value: "" },
  { label: "citizenship front", value: "citizenship-front" },
  { label: "citizenship back", value: "citizenship-back" },
  { label: "PAN", value: "pan" },
  { label: "passport", value: "passport" },
  { label: "Driving licence", value: "licence" },
];

let occupationList = [
  { label: " Please Select ", value: "" },
  { label: " Corporate professional ", value: "corporate_professional" },
  { label: "Self-Employed", value: "self_employed" },
  { label: "Business owners", value: "business_owners" },
  { label: "Entrepreneurs", value: "entrepreneurs" },
  { label: "Freelancers", value: "freelancers" },
  { label: "Consultants", value: "consultants" },
  { label: "Farmers", value: "farmers" },
  { label: "Doctors", value: "doctors" },
  { label: "Lawyers", value: "lawyers" },
  { label: "Engineers", value: "engineers" },
  { label: "Architects", value: "architects" },
  { label: "Chartered Accountants", value: "chartered_accountants" },
  { label: "Company Secretaries", value: "company_secretaries" },
  { label: "Government officials", value: "government_officials" },
  { label: "Politicians", value: "politicians" },
];
const intiKyc = {
  subscriber_id: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  mobile_no: "",
};

const Profile = () => {
  const { mainData, loading, cameraData, success, processing } = useSelector(
    (state) => ({
      mainData: state.profile.profileData,
      loading: state?.profile?.loaders?.profile,
      success: state?.getsubscriberkyc?.success,
      cameraData: state?.cameras?.cameraData,
      processing: state?.getsubscriberkyc?.processing,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useParams();
  const [selectedTab, setSelectedTab] = useState("profile");
  const [kyc, setKyc] = useState(intiKyc);
  const [error, setError] = useState({
    error: "",
    message: { type: "", message: "", title: "" },
  });
  const [disableFields, setDisableFields] = useState(true);
  const [showEditOption, setShowEditOption] = useState(false);
  const [upload_doc, setUpload_Doc] = useState("");
  const [file, setFile] = useState("");
  const [kyc_image, setKyc_Image] = useState({});
  const [kyc_image2, setKyc_Image2] = useState({});
  const [documents, setDocuments] = useState("");
  const [documents2, setDocuments2] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [previewURLdoc2, setPreviewURLdoc2] = useState("");
  const [document_name, setDocument_name] = useState("");
  const [document_name2, setDocument_name2] = useState("");
  const [proccessing, setproccessing] = useState(false);
  const [kycmessage, setKycMessage] = useState("");
  const [country, setCountry] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const uploadRef = useRef();

  const [state, setState] = useState({
    showEditOption: false,
    showEditOption1: false,
    disableFields: true,
    disableFields1: true,
    showSnackbar: false,
    loading: false,
    loading1: false,
    data: {
      username: "",
      name: "",
      phone: "",
      email: "",
      address: "",
    },
    error: {
      username: "",
      name: "",
      phone: "",
      email: "",
      address: "",
    },
  });

  useMemo(() => {
    if (Object.keys(path)?.length > 0) {
      setSelectedTab("kyc_verify");
    } else {
      setSelectedTab("profile");
    }
  }, [path]);

  useEffect(() => {
    let handler = (e) => {
      if (uploadRef.current && !uploadRef.current.contains(e.target)) {
        setIsOpen(false);
        setUpload_Doc("");
      }
    };

    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    if ((Object.keys(error).length > 0 && document_name) || kyc.document1?.length > 0) {
      const initialError = { ...error };
      const { kyc_image, ...remainingError } = initialError?.error;
      const updatedError = {
        error: remainingError,
        message: { type: "", message: "", title: "" },
      };
      setError(updatedError);
    }

    if ((Object.keys(error).length > 0 && document_name2) || kyc.document1?.length > 0) {
      const initialError = { ...error };
      const { kyc_image, ...remainingError } = initialError?.error;
      const updatedError = {
        error: remainingError,
        message: { type: "", message: "", title: "" },
      };
      setError(updatedError);
    }
  }, [document_name, kyc?.document2]);

  useEffect(() => {
    const token = localStorage.getItem("_Bearer");
    if (token && mainData?.id) {
      // dispatch(getCameras(mainData?.id));
      dispatch(getSubscriberKyc(mainData?.id));
    }
    if (mainData?.kyc_verified === null) {
      setShowEditOption(true);
      setDisableFields(false);
      setKycMessage("Fillup KYC");
    }
    if (mainData?.kyc_verified === 0) {
      setShowEditOption(false);
      setDisableFields(true);
      setKycMessage("KYC Verification Rejected");
    }
    if (mainData?.kyc_verified === 1) {
      setKycMessage("KYC Verified");
    }
    if (mainData?.kyc_verified === 2) {
      setKycMessage("KYC verification pending");
      setShowEditOption(true);
      setShowEditOption(false);
    }
    if (mainData?.id) {
      setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          name: mainData?.subscriber_details?.full_name,
          phone: mainData?.subscriber_details?.mobile_no,
          address: mainData?.subscriber_details?.address,
          username: mainData?.username,
          email: mainData?.email,
        },
      }));
    }
  }, [mainData]);

  useEffect(() => {
    if (success) {
      Object.keys(success).map((index) => {
        if (acceptedKeys.includes(index)) {
          kyc[index] = success[index];
        }
      });
      setKyc({
        ...kyc,
        document1: success?.document1,
        document2: success?.document2,
        subscriber_id: mainData?.subscriber_details?.subscriber_id,
        full_name: mainData?.subscriber_details?.full_name,
        email: mainData?.email,
        mobile_no: mainData?.subscriber_details?.mobile_no,
        ...(success?.document1_name && { document1_name: success?.document1_name }),
        ...(success?.document2_name && { document2_name: success?.document2_name }),
      });
      dispatch(getCountries(10000)).then((res) => {
        if (res?.status === 200) {
          setCountry(res?.data);
        }
      });
    }
  }, [success]);
  console.log("kyc", kyc)
  const ICON_SIZE = 18;
  const onChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    kyc[name] = value;
    if (error?.error && error?.error[name] !== "") {
      error.error[name] = "";
      setError({ ...error });
    } else {
      setKyc({ ...kyc, kyc });
    }
    if (mainData?.kyc_verified == null) {
      setShowEditOption(true);
      setDisableFields(false);
    }
  };
  const acceptedKeys = [
    "first_name",
    "middle_name",
    "last_name",
    "mobile_no",
    "email",
    "address",
  ];

  const acceptedKeysProfile = ["username", "name", "phone", "email", "address"];

  const validationRule = {
    first_name: ["required"],
    last_name: ["required"],
    mobile_no: ["required", "min:10", "max:10", "numeric"],
    email: ["required", "email"],
    // address: ["required"],
  };

  const onSubmit = async (e) => {
    setproccessing(true);
    let requestData = {};

    Object.keys(kyc).map((index) => {
      if (acceptedKeys.includes(index)) {
        requestData[index] = kyc[index];
      }
    });
    console.log("requestData", requestData)
    const doc2Data = new FormData();
    // doc2Data.append("image", kyc_image2);
    // doc2Data.append("model", "subscribers");

    let localvalidationerror = false;
    let validation = localValidation(requestData, validationRule, error, localvalidationerror);
    if (validation.localvalidationerror) {
      setError({
        error: validation.error,
        message: { type: "", message: "", title: "" },
      });
      var test = document.getElementsByClassName("has-error");
      if (test[0]) {
        scrollToPositionFrom(window.scrollY, test[0].getBoundingClientRect().top + window.scrollY);
      }
      setproccessing(false);
    }
    // alert('1')
    // if ((kyc && kyc?.document1 === undefined) || kyc?.document1?.length <= 0) {
    //   if (Object.keys(kyc_image).length === 0 && document_name === "") {
    //     setError({
    //       error: true,
    //       error: {
    //         ...error,
    //         kyc_image: ["Document is mandotary"],
    //       },
    //       message: {
    //         type: "warngin",
    //         message: ["Document one is mandotary"],
    //         title: ["Document one is mandotary"],
    //       },
    //     });
    //   }
    //   setproccessing(false);
    //   return;
    // }
    // alert("2")
    if (
      document_name === "citizenship-front" &&
      !document_name2 &&
      mainData?.kyc_verified === null
    ) {
      setError({
        error: true,
        error: {
          ...error,
          kyc_image: ["Citizenship Back Side image is mandotary"],
        },
        message: {
          type: "warngin",
          message: ["Document one is mandotary"],
          title: ["Document one is mandotary"],
        },
      });
      setproccessing(false);
      return;
    } else {
      //KYC CRUD
      const formData = new FormData();
      formData.append("image", kyc_image);
      formData.append("model", "subscribers");

      if (!kyc_image?.name && kyc?.document1 && kyc_image2?.name) {
        fetch(Config.BaseUrl + `/subscriber/services/v1/image`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + loginToken(),
          },
          body: doc2Data,
        })
          .then((response) => {
            return response.json();
          })
          .then((res2) => {
            const formData = new FormData();
            formData.append("subscriber_id", kyc?.subscriber_id);
            // formData.append("document1", kyc?.document1);
            // formData.append("document2", res2?.id);
            formData.append(
              "first_name",
              requestData.first_name !== "" ? requestData.first_name : kyc.first_name
            );
            formData.append(
              "middle_name",
              requestData.middle_name !== "" ? requestData.middle_name : kyc.middle_name
            );
            formData.append(
              "last_name",
              requestData.last_name !== "" ? requestData.last_name : kyc.last_name
            );
            formData.append(
              "mobile_no",
              requestData.mobile_no !== "" ? requestData.mobile_no : kyc.mobile_no
            );
            // formData.append("gender", requestData.gender);
            formData.append("email", requestData.email);
            formData.append("address", requestData.address);

            // formData.append("document1_name", kyc?.document1_name);
            // formData.append("document2_name", document_name2);
            if ((success?.id && mainData?.kyc_verified !== null) || mainData?.kyc_verified === 2) {
              let id = success?.id;
              formData.append("_method", "patch");
              let jsonData = {};
              formData.forEach((value, key) => {
                jsonData[key] = value;
              });
              dispatch(EditSubscribersKyc(id, jsonData)).then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                  dispatch(successAlert(res?.data?.message));
                  setShowEditOption(false);
                  setDisableFields(true);
                  setKycMessage("KYC verification pending");
                  dispatch(getProfileData());
                  dispatch(getSubscriberKyc(mainData?.id));
                } else if (res?.status === 422) {
                  dispatch(errorAlert("Form error exists, please check you form data."));
                }
                //  else {
                //     dispatch(errorAlert(res?.data?.message));
                // }
                setproccessing(false);
                navigate("/profile/kyc");
              });
            } else {
              formData.append("_method", "post");
              let jsonData = {};
              formData.forEach((value, key) => {
                jsonData[key] = value;
              });

              dispatch(createSubscribersKyc(jsonData));
              dispatch(getProfileData());
              setKycMessage("KYC verification pending");
              dispatch(getSubscriberKyc(mainData?.id));
              setproccessing(false);
              navigate("/profile/kyc");
            }
          })
          .catch((error) => {
            if (error.response.status == 422) {
              setError({
                ...error,
                error: error.response.data.message,
              });
            }
          });
        setproccessing(false);
        navigate("/profile/kyc");
      }

      if (kyc_image?.name && kyc_image2?.name) {
        fetch(Config.BaseUrl + `/subscriber/services/v1/image`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + loginToken(),
          },
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((res) => {
            if (res?.message == "image added successfully") {
              if (kyc_image2?.name) {
                fetch(Config.BaseUrl + `/subscriber/services/v1/image`, {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer " + loginToken(),
                  },
                  body: doc2Data,
                })
                  .then((response) => {
                    return response.json();
                  })
                  .then((res2) => {
                    const formData = new FormData();
                    formData.append("subscriber_id", kyc?.subscriber_id);
                    // formData.append("document1", res?.id);
                    // formData.append("document2", res2?.id);
                    // formData.append("document1_name", document_name);
                    // formData.append("document2_name", document_name2);

                    formData.append(
                      "first_name",
                      requestData.first_name !== "" ? requestData.first_name : kyc.first_name
                    );
                    formData.append(
                      "middle_name",
                      requestData.middle_name !== "" ? requestData.middle_name : kyc.middle_name
                    );
                    formData.append(
                      "last_name",
                      requestData.last_name !== "" ? requestData.last_name : kyc.last_name
                    );
                    formData.append(
                      "mobile_no",
                      requestData.mobile_no !== "" ? requestData.mobile_no : kyc.mobile_no
                    );
                    // formData.append("gender", requestData.gender);
                    // formData.append("marital_status", requestData.marital_status);
                    // formData.append("father_name", requestData.father_name);
                    // formData.append("occupation", requestData.occupation);
                    // formData.append("nationality", requestData.nationality);
                    formData.append("email", requestData.email);
                    formData.append("address", requestData.address);

                    if (
                      (success?.id && mainData?.kyc_verified !== null) ||
                      mainData?.kyc_verified === 2
                    ) {
                      let id = success?.id;
                      formData.append("_method", "patch");
                      let jsonData = {};
                      formData.forEach((value, key) => {
                        jsonData[key] = value;
                      });
                      dispatch(EditSubscribersKyc(id, jsonData)).then((res) => {
                        if (res?.status === 200 || res?.status === 201) {
                          dispatch(successAlert(res?.data?.message));
                          setDisableFields(true);
                          setKycMessage("KYC verification pending");
                          dispatch(getProfileData());
                          dispatch(getSubscriberKyc(mainData?.id));
                        } else if (res?.status === 422) {
                          dispatch(errorAlert("Form error exists, please check you form data."));
                        }
                        // else {
                        //     dispatch(errorAlert(res?.data?.message));
                        // }
                        setproccessing(false);
                        navigate("/profile/kyc");
                      });
                    } else {
                      formData.append("_method", "post");
                      let jsonData = {};
                      formData.forEach((value, key) => {
                        jsonData[key] = value;
                      });

                      dispatch(createSubscribersKyc(jsonData)).then((res) => {
                        if (res?.status === 200 || res?.status === 201) {
                          dispatch(successAlert(res?.data?.message));
                          // setShowEditOption(false);
                          setKycMessage("KYC verification pending");
                          navigate("/profile/kyc");

                          dispatch(getSubscriberKyc(mainData?.id));
                          dispatch(getProfileData());
                        } else if (res?.status === 422) {
                          dispatch(errorAlert("Form error exists, please check you form data."));
                        }
                        // else {
                        //       dispatch(errorAlert(res?.data?.message));
                        //   }
                        setproccessing(false);
                        navigate("/profile/kyc");
                      });
                    }
                    setproccessing(false);
                    navigate("/profile");
                  })
                  .catch((error) => {
                    if (error.response.status == 422) {
                      setError({
                        ...error,
                        error: error.response.data.message,
                      });
                    }
                  });
              }
            }
          })
          .catch((error) => {
            if (error.response.status == 422) {
              setError({
                ...error,
                error: error.response.data.message,
              });
            }
          });
      }

      if (kyc_image?.name && !kyc_image2?.name) {
        fetch(Config.BaseUrl + `/subscriber/services/v1/image`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + loginToken(),
          },
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            let ids = data?.id;
            const formData = new FormData();
            formData.append("subscriber_id", kyc?.subscriber_id);
            // formData.append("document1", ids);
            // kyc?.document2 === "" ||
            //   (kyc?.document2 === "undefined" && formData.append("document2", kyc?.document2));
            // formData.append("document2",kyc?.document2);

            formData.append(
              "middle_name",
              requestData.middle_name !== "" ? requestData.middle_name : kyc?.middle_name
            );
            formData.append(
              "middle_name",
              requestData.middle_name !== "" ? requestData.middle_name : kyc?.middle_name
            );
            formData.append(
              "last_name",
              requestData.last_name !== "" ? requestData.last_name : kyc?.last_name
            );
            formData.append(
              "mobile_no",
              requestData.mobile_no !== "" ? requestData.mobile_no : kyc?.mobile_no
            );
            // formData.append("gender", requestData.gender);
            // formData.append("marital_status", requestData.marital_status);
            // formData.append("father_name", requestData.father_name);
            // formData.append("occupation", requestData.occupation);
            // formData.append("nationality", requestData.nationality);
            formData.append("email", requestData.email);
            // formData.append("document1_name", document_name);
            // formData.append("document2_name", kyc?.document2_name);
            formData.append("address", requestData.address);

            if ((success?.id && mainData?.kyc_verified !== null) || mainData?.kyc_verified === 2) {
              let id = success?.id;
              formData.append("_method", "patch");
              let jsonData = {};
              formData.forEach((value, key) => {
                jsonData[key] = value;
              });
              dispatch(EditSubscribersKyc(id, jsonData)).then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                  dispatch(successAlert(res?.data?.message));
                  setShowEditOption(false);
                  setDisableFields(true);
                  setKycMessage("KYC verification pending");
                  dispatch(getProfileData());
                  dispatch(getSubscriberKyc(mainData?.id));
                } else if (res?.status === 422) {
                  dispatch(errorAlert("Form error exists, please check you form data."));
                } else {
                  dispatch(errorAlert(res?.data?.message));
                }
                setproccessing(false);
                navigate("/profile/kyc");
              });
            } else {
              formData.append("_method", "post");
              let jsonData = {};
              formData.forEach((value, key) => {
                jsonData[key] = value;
              });
              dispatch(createSubscribersKyc(jsonData)).then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                  dispatch(successAlert(res?.data?.message));
                  dispatch(getProfileData());
                  setKycMessage("KYC verification pending");
                  dispatch(getSubscriberKyc(mainData?.id));
                  navigate("/profile/kyc");
                } else if (res?.status === 422) {
                  dispatch(errorAlert("Form error exists, please check you form data."));
                } else {
                  dispatch(errorAlert(res?.data?.message));
                }
                setproccessing(false);
                navigate("/profile/kyc");
              });
            }
            setproccessing(false);
            navigate("/profile/kyc");
          })
          .catch((error) => {
            // Handle errors
            console.error("Error uploading image:", error);
          });
      }

      if (!isNaN(kyc?.document1) && !kyc_image2?.name && !kyc?.document2) {
        const formData = new FormData();
        formData.append("subscriber_id", kyc?.subscriber_id);
        // formData.append("document1", kyc?.document1); // error store calls twice  //
        // formData.append("document1", kyc?.document1);
        formData.append(
          "first_name",
          requestData.first_name !== "" ? requestData.first_name : kyc?.first_name
        );
        formData.append(
          "middle_name",
          requestData.middle_name !== "" ? requestData.middle_name : kyc?.middle_name
        );
        formData.append(
          "last_name",
          requestData.last_name !== "" ? requestData.last_name : kyc?.last_name
        );
        formData.append(
          "mobile_no",
          requestData.mobile_no !== "" ? requestData.mobile_no : kyc?.mobile_no
        );
        // formData.append("gender", requestData.gender);
        // formData.append("marital_status", requestData.marital_status);
        // formData.append("father_name", requestData.father_name);
        // formData.append("occupation", requestData.occupation);
        // formData.append("nationality", requestData.nationality);
        formData.append("email", requestData.email);
        // formData.append("document1_name", kyc?.document1_name);
        // formData.append("document2_name", kyc?.document2_name);
        formData.append("address", requestData.address);

        if ((success?.id && mainData?.kyc_verified !== null) || mainData?.kyc_verified === 2) {
          let id = success?.id;
          formData.append("_method", "patch");
          let jsonData = {};
          formData.forEach((value, key) => {
            jsonData[key] = value;
          });
          dispatch(EditSubscribersKyc(id, jsonData)).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              dispatch(successAlert(res?.data?.message));
              setShowEditOption(false);
              setDisableFields(true);
              setKycMessage("KYC verification pending");
              dispatch(getProfileData());
              dispatch(getSubscriberKyc(mainData?.id));
            } else if (res?.status === 422) {
              dispatch(errorAlert("Form error exists, please check you form data."));
            } else {
              dispatch(errorAlert(res?.data?.message));
            }
            setproccessing(false);
            navigate("/profile/kyc");
          });
        } else {
          formData.append("_method", "post");
          let jsonData = {};
          formData.forEach((value, key) => {
            jsonData[key] = value;
          });

          dispatch(createSubscribersKyc(jsonData)).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              dispatch(successAlert(res?.data?.message));
              // setShowEditOption(false)
              setKycMessage("KYC verification pending");
              dispatch(getSubscriberKyc(mainData?.id));
              navigate("/profile/kyc");
            } else if (res?.status === 422) {
              dispatch(errorAlert("Form error exists, please check you form data."));
            }
            //  else {
            //     dispatch(errorAlert(res?.data?.message));
            // }
            setproccessing(false);
            navigate("/profile/kyc");
          });
        }
      }

      if (kyc?.document2 !== "" && !kyc_image?.name && !kyc?.document1) {
        const formData = new FormData();
        formData.append("subscriber_id", kyc?.subscriber_id);
        // formData.append("document2", kyc?.document2);
        formData.append(
          "first_name",
          requestData.first_name !== "" ? requestData.first_name : kyc?.first_name
        );
        formData.append(
          "middle_name",
          requestData.middle_name !== "" ? requestData.middle_name : kyc?.middle_name
        );
        formData.append(
          "last_name",
          requestData.last_name !== "" ? requestData.last_name : kyc?.last_name
        );
        formData.append(
          "mobile_no",
          requestData.mobile_no !== "" ? requestData.mobile_no : kyc?.mobile_no
        );
        // formData.append("gender", requestData.gender);
        // formData.append("marital_status", requestData.marital_status);
        // formData.append("father_name", requestData.father_name);
        // formData.append("occupation", requestData.occupation);
        // formData.append("nationality", requestData.nationality);
        formData.append("email", requestData.email);
        // formData.append("document1_name", kyc?.document1_name);
        // formData.append("document2_name", kyc?.document2_name);
        formData.append("address", requestData.address);

        if ((success?.id && mainData?.kyc_verified !== null) || mainData?.kyc_verified === 2) {
          let id = success?.id;
          formData.append("_method", "patch");
          let jsonData = {};
          formData.forEach((value, key) => {
            jsonData[key] = value;
          });
          dispatch(EditSubscribersKyc(id, jsonData)).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              dispatch(successAlert(res?.data?.message));
              setShowEditOption(false);
              setDisableFields(true);
              setKycMessage("KYC verification pending");
              dispatch(getProfileData());
              dispatch(getSubscriberKyc(mainData?.id));
            } else if (res?.status === 422) {
              dispatch(errorAlert("Form error exists, please check you form data."));
            } else {
              dispatch(errorAlert(res?.data?.message));
            }
            setproccessing(false);
            navigate("/profile/kyc");
          });
        } else {
          formData.append("_method", "post");
          let jsonData = {};
          formData.forEach((value, key) => {
            jsonData[key] = value;
          });

          dispatch(createSubscribersKyc(jsonData)).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              dispatch(successAlert(res?.data?.message));
              dispatch(getProfileData());
              navigate("/profile/kyc");
              setKycMessage("KYC verification pending");
              dispatch(getSubscriberKyc(mainData?.id));
            } else if (res?.status === 422) {
              dispatch(errorAlert("Form error exists, please check you form data."));
            } else {
              dispatch(errorAlert(res?.data?.message));
            }
            setproccessing(false);
            navigate("/profile/kyc");
          });
        }
      }

      if (!kyc_image?.name && kyc?.document1 && !kyc_image2?.name && kyc?.document2) {
        const formData = new FormData();
        formData.append("subscriber_id", kyc?.subscriber_id);
        // formData.append("document1", kyc?.document1);
        // formData.append("document2", kyc?.document2);
        formData.append("first_name", requestData?.first_name);
        formData.append("middle_name", requestData?.middle_name);
        formData.append("last_name", requestData?.last_name);
        formData.append("mobile_no", requestData.mobile_no);
        // formData.append("gender", requestData.gender);
        // formData.append("marital_status", requestData.marital_status);
        // formData.append("father_name", requestData.father_name);
        // formData.append("occupation", requestData.occupation);
        // formData.append("nationality", requestData.nationality);
        formData.append("email", requestData.email);
        // formData.append("document1_name", kyc?.document1_name);
        // formData.append("document2_name", kyc?.document2_name);
        formData.append("address", requestData.address);

        if (mainData?.kyc_verified !== null) {
          let id = success?.id;
          formData.append("_method", "patch");
          let jsonData = {};
          // formData.delete("mobile_no")
          formData.forEach((value, key) => {
            jsonData[key] = value;
          });
          dispatch(EditSubscribersKyc(id, jsonData)).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              dispatch(successAlert(res?.data?.message));
              setShowEditOption(false);
              setDisableFields(true);
              setKycMessage("KYC verification pending");
              dispatch(getProfileData());
              dispatch(getSubscriberKyc(mainData?.id));
            } else if (res?.status === 422) {
              dispatch(errorAlert("Form error exists, please check you form data."));
            }
            setproccessing(false);
            navigate("/profile/kyc");
          });
        } else {
          formData.append("_method", "post");
          let jsonData = {};
          formData.forEach((value, key) => {
            jsonData[key] = value;
          });

          dispatch(createSubscribersKyc(jsonData)).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              dispatch(successAlert(res?.data?.message));
              setKycMessage("KYC verification pending");
              dispatch(getProfileData());
              navigate("/profile/kyc");
              setproccessing(false);

              dispatch(getSubscriberKyc(mainData?.id));
            } else if (res?.status === 422) {
              dispatch(errorAlert("Form error exists, please check you form data."));
            } else {
              dispatch(errorAlert(res?.data?.message));
            }

            setproccessing(false);
          });
        }
        setproccessing(false);
        navigate("/profile/kyc");
      }
      setKyc_Image("");
      setKyc_Image2("");
    }
  };

  const documetHandler = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    if (
      value === "citizenship-front" ||
      value === "pan" ||
      value === "passport" ||
      value === "licence"
    ) {
      setUpload_Doc(value);
      setDocument_name(value);
    }
    if (value === "citizenship-back") {
      setUpload_Doc(value);
      setDocument_name2(value);
    }
    setIsOpen(true);
  };

  const handleImageChange = (event) => {
    const name = event.target.name;
    const input = event.target;
    const file = input.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (name === "document1") {
          setPreviewURL(reader.result);
        } else if (name === "document2") {
          setPreviewURLdoc2(reader.result);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewURL("");
      setPreviewURLdoc2("");
    }
  };

  const onLogoChange = (e) => {
    e.preventDefault();
    let { name } = e.target;
    let { value } = e.target;
    // let kyc = { ...kyc };
    // let error = { ...error };
    kyc[name] = value;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      kyc[name] = file;
      reader.onload = () => {
        let img = new Image();
        img.onload = () => {
          kyc["logo_height"] = img.height;
          kyc["logo_width"] = img.width;
        };
        img.src = reader.result;
        kyc[name] = img.src;
        if (error[name] !== "") {
          error[name] = "";
        }
        if (name == "document1") {
          setKyc_Image(file);
        }
        if (name == "document2") {
          setKyc_Image2(file);
        }
      };
      reader.readAsDataURL(file);
    }
    setUpload_Doc("");
  };

  let gov_issued_doc = null;
  if (kyc?.document1) {
    gov_issued_doc = (
      <img
        src={Config.BaseUrl + "/images/" + kyc?.document1}
        className="img-responsive ml-auto mr-auto h-24"
        style={{ maxHeight: "150px", width: "200px" }}
      />
    );
  }

  let gov_issued_doc_opt = null;

  if (kyc?.document2) {
    gov_issued_doc_opt = (
      <img
        src={Config.BaseUrl + "/images/" + kyc?.document2}
        className="img-responsive ml-auto mr-auto h-24"
        style={{ maxHeight: "150px", width: "200px" }}
      />
    );
  }
  const onCancel = () => {
    navigate("/");
    setShowEditOption(!showEditOption);
    setDisableFields(!disableFields);
  };

  const handleEdit = () => {
    if (mainData?.id) {
      setShowEditOption(!showEditOption);
      setDisableFields(!disableFields);
    } else {
      onCancel();
    }
  };

  const profileOnChnage = (e) => {
    let { name, value } = e.target;
    let _data = { ...state.data };
    let _error = { ...state.error };
    _data[name] = value;
    if (_error[name] !== "") _error[name] = "";
    setState({ ...state, data: _data, error: _error });
  };

  const updateProfile = (e) => {
    let requestData = {};
    Object.keys(state?.data).map((index) => {
      if (acceptedKeysProfile.includes(index)) {
        requestData[index] = state.data[index];
      }
    });

    const validationRule = {
      username: ["required"],
      phone: ["required", "min:10", "max:10", "numeric"],
      name: ["required"],
      email: ["required"],
      address: ["required"],
    };

    setState({
      ...state,
      disableFields: !disableFields,
    });

    let localvalidationerror = false;
    let validation = localValidation(
      requestData,
      validationRule,
      state.error,
      localvalidationerror
    );
    if (validation.localvalidationerror) {
      setState({
        error: validation.error,
        message: { type: "", message: "", title: "" },
      });
      var test = document.getElementsByClassName("has-error");
      if (test[0]) {
        scrollToPositionFrom(window.scrollY, test[0].getBoundingClientRect().top + window.scrollY);
      }
    } else {
      // dispatch(editProfile(mainData?.subscriber_details?.id,state?.data))
    }
  };

  const handlerDiable = () => {
    setState({
      ...state,
      disableFields: !disableFields,
    });
  };

  let countriesList =
    country?.length > 0 &&
    country.map((country) => {
      return {
        label: country.name,
        value: country.name,
      };
    });
  return (
    <React.Fragment>
      {Config.NodeEnv === "devs" ? (
        <NotFoundPage />
      ) : (
        <div className="w-full   max-w-5xl bg-white rounded-2xl border-white shadow mx-auto mt-10 p-1 md:p-4  ">
          <div className="grid md:grid-cols-8 ">
            <div className="col-span-2 md:border-r md:min-h-[50vh]">
              <ul className="mt-8 flex md:grid gap-2 ml-3 md:ml-8">
                <li
                  className={`cursor-pointer px-4 py-3 text-sm rounded-lg w-24 md:w-32 xl:w-48 flex gap-2 items-center ${selectedTab === "profile" ? "bg-gray-200" : ""
                    }`}
                  // onClick={() => {setSelectedTab("profile");setDisableFields(true);setShowEditOption(false)}}
                  onClick={() => {
                    setSelectedTab("profile");
                    navigate("/profile");
                    setState({
                      ...state,
                      error: {
                        username: "",
                        name: "",
                        phone: "",
                        email: "",
                        address: "",
                      },
                      disableFields: true,
                    });
                  }}
                >
                  <User size={15} /> Profile
                </li>

                {(
                  <li
                    className={`cursor-pointer px-4 py-3 text-sm rounded-lg w-24 md:w-32 xl:w-48 flex gap-2 items-center ${selectedTab === "kyc_verify" ? "bg-gray-200" : ""
                      }`}
                    onClick={() => {
                      setSelectedTab("kyc_verify");
                      navigate("/profile/kyc");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14 5.66666V3.99999C14 3.26361 13.403 2.66666 12.6667 2.66666H3.33333C2.59695 2.66666 2 3.26361 2 3.99999V7.33332C2 8.0697 2.59695 8.66666 3.33333 8.66666H7.25M12.6667 9.33332V7.99999C12.6667 7.26361 12.0697 6.66666 11.3333 6.66666C10.597 6.66666 10 7.26361 10 7.99999V9.33332M9.33333 13.3333H13.3333C13.7015 13.3333 14 13.0348 14 12.6667V9.99999C14 9.6318 13.7015 9.33332 13.3333 9.33332H9.33333C8.96514 9.33332 8.66667 9.6318 8.66667 9.99999V12.6667C8.66667 13.0348 8.96514 13.3333 9.33333 13.3333Z"
                        stroke="black"
                        stroke-width="1.1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle cx="5" cy="5.66666" r="1" fill="black" />
                      <circle cx="8" cy="5.66666" r="1" fill="black" />
                    </svg>
                    KYC
                  </li>
                )}
              </ul>
            </div>
            <div className="col-span-6  ">
              <div className="">
                {
                  loading ? (
                    <LoadingDiv />
                  ) : selectedTab === "profile" ? (
                    <div className="px-3 md:px-6 mt-6">
                      <p className="text-lg font-semibold">Personal Information</p>
                      <div className="grid md:grid-cols-2 gap-4 break-words mt-2.5 p-1">
                        <Input
                          label={"Username"}
                          name={"username"}
                          info={"Subscriber User Name"}
                          value={state.data?.username}
                          prefix={<AtSign size={18} />}
                          error={state?.error?.username}
                          onChange={(e) => profileOnChnage(e)}
                          disabled={state.disableFields}
                        />
                        <Input
                          label={"Name"}
                          name={"name"}
                          info={"Subscriber Full Name"}
                          value={state.data?.name}
                          error={state.error?.name}
                          onChange={(e) => profileOnChnage(e)}
                          disabled={state.disableFields}
                        />

                        <Input
                          label={"Phone"}
                          name={"phone"}
                          type={"number"}
                          info={"Subscriber Mobile Number"}
                          // value={mainData?.subscriber_details?.mobile_no}
                          value={state?.data?.phone}
                          error={state.error?.phone}
                          onChange={(e) => profileOnChnage(e)}
                          disabled={state.disableFields}
                        />
                        <Input
                          label={"Email"}
                          name={"email"}
                          info={"Subscriber Email"}
                          // value={mainData?.email}
                          value={state.data?.email}
                          error={state.error?.email}
                          onChange={(e) => profileOnChnage(e)}
                          disabled={state.disableFields}
                        />

                        <Input
                          label={"Address"}
                          name={"address"}
                          type={"text"}
                          info={"Subscriber Address"}
                          // className={"col-span-2"}
                          // value={mainData?.subscriber_details?.address}
                          value={state?.data?.address}
                          error={state.error?.address}
                          onChange={(e) => profileOnChnage(e)}
                          disabled={state.disableFields}
                        />
                      </div>
                    </div>
                  ) : null
                }
                {selectedTab === "kyc_verify" && (
                  <div className="containers">
                    {proccessing ? (
                      <LoadingDiv />
                    ) : (
                      <div className="px-1 mt-6">
                        {mainData?.kyc_verified === 2 &&
                          <Alert message={kycmessage} variant={'warning'} />
                        }

                        {mainData?.kyc_verified === null &&
                          <Alert message={kycmessage} variant={'info'} />}

                        {mainData?.kyc_verified === 1 && <Alert message={kycmessage} variant={'success'} />}
                        {mainData?.kyc_verified === 0 && <Alert message={kycmessage + '. ' + mainData?.rejected_reason} variant={'danger'} />}

                        <br />

                        <div className="flex justify-between">
                          <>
                            <p className="text-lg font-semibold md:text-md">
                              {mainData?.kyc_verified == null || mainData?.kyc_verified === 2
                                ? "Create KYC for Subscriber"
                                : showEditOption && mainData?.kyc_verified == 0
                                  ? "Update KYC for Subscriber"
                                  : "Subscriber KYC Details"}
                            </p>
                          </>
                          <div className="flex items-center">

                            {(mainData?.kyc_verified === 2 || mainData?.kyc_verified === 0) && (
                              <>
                                <Button onClick={() => handleEdit()}> Edit </Button>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="grid  md:grid-cols-3 gap-4 break-words mt-2.5 mb-4 p-1">
                          <Input
                            label={"First Name *"}
                            name="first_name"
                            value={kyc?.first_name}
                            error={error?.error?.first_name}
                            onChange={(e) => onChange(e)}
                            disabled={disableFields}
                          />

                          <Input
                            label={"Middle Name"}
                            name="middle_name"
                            value={kyc?.middle_name}
                            error={error?.error?.middle_name}
                            onChange={(e) => onChange(e)}
                            disabled={disableFields}
                          />
                          <Input
                            label={"Last Name *"}
                            name="last_name"
                            value={kyc?.last_name}
                            error={error?.error?.last_name}
                            onChange={(e) => onChange(e)}
                            disabled={disableFields}
                          />
                        </div>
                        <div className="grid md:grid-cols-2 gap-4 break-words mb-4">
                          <Input
                            label={"Email *"}
                            name="email"
                            value={kyc?.email}
                            error={error?.error?.email}
                            onChange={(e) => onChange(e)}
                            disabled={disableFields}
                          />
                          <Input
                            label={"Mobile No. *"}
                            value={kyc?.mobile_no}
                            name="mobile_no"
                            error={error?.error?.mobile_no}
                            onChange={(e) => onChange(e)}
                            disabled={disableFields}
                          />

                        </div>

                        <Input
                          label={"Address"}
                          value={kyc?.address}
                          name="address"
                          error={error?.error?.address}
                          onChange={(e) => onChange(e)}
                          disabled={disableFields}
                        />




                        <div class="container px-4">
                          <div class="row gx-5">
                            <div className="flex justify-between  mt-0">
                              {!isNaN(kyc?.document1) && kyc?.document1_name !== "undefined" && (
                                <div class="col">
                                  <label className="form-label mb-3 text-sm text-gray-500">
                                    {kyc?.document1_name}
                                  </label>
                                  <div class="p-3 border bg-light custom-border text-center">
                                    <div className="media-container">
                                      <div
                                        className={
                                          kyc?.document1 === ""
                                            ? "bg-light text-center"
                                            : "text-center media"
                                        }
                                        onClick={() => {
                                          disableFields ? setFile("null") : setFile("document1");
                                        }}
                                      >
                                        {gov_issued_doc ? gov_issued_doc : gov_issued_doc}
                                      </div>
                                    </div>
                                    <div
                                      className="popup-media"
                                      style={{
                                        display: file === "document1" ? "block" : "none",
                                      }}
                                    >
                                      <span
                                        onClick={() => {
                                          setFile("null");
                                        }}
                                      >
                                        &times;
                                      </span>
                                      {file === "document1" && (
                                        <img
                                          src={`${Config.BaseUrl}` + "/images/" + kyc?.document1}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {!isNaN(kyc?.document2) && kyc?.document2_name !== "undefined" && (
                                <div class="col">
                                  <label className="form-label mb-2 text-sm text-gray-500">
                                    {kyc?.document2_name}
                                  </label>
                                  <div class="p-3 border bg-light custom-border text-center">
                                    <div className="media-container">
                                      <div
                                        className={
                                          kyc?.document2 === ""
                                            ? "bg-light text-center"
                                            : "text-center media"
                                        }
                                        onClick={() => {
                                          disableFields ? setFile("null") : setFile("document2");
                                        }}
                                      >
                                        {gov_issued_doc_opt
                                          ? gov_issued_doc_opt
                                          : gov_issued_doc_opt}
                                      </div>
                                    </div>
                                    <div
                                      className="popup-media"
                                      style={{
                                        display: file === "document2" ? "block" : "none",
                                      }}
                                    >
                                      <span
                                        onClick={() => {
                                          setFile("null");
                                        }}
                                      >
                                        &times;
                                      </span>
                                      {file === "document2" && (
                                        <img
                                          src={`${Config.BaseUrl}` + "/images/" + kyc?.document2}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end mt-4">
                          {/* <div> */}

                          {showEditOption && (
                            <div className="flex">
                              <Button onClick={(e) => onSubmit(e)}>Submit</Button>
                              &nbsp;&nbsp;
                              <Button onClick={(e) => onCancel(e)}>Cancel</Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <script src="./js/uploadfile.js"></script>
    </React.Fragment>
  );
};

export default Profile;



const Alert = ({ message, variant }) => {
  const variantColor = (variant) => {
    if (variant === 'danger') {
      return 'bg-rose-200 text-rose-700 border-rose-400'
    }
    else if (variant === 'success') {
      return 'bg-green-200 text-green-700 border-green-400'
    }
    else if (variant === 'warning') {
      return 'bg-orange-200 text-orange-700 border-orange-400'
    }
    else if (variant === 'info') {
      return 'bg-blue-200 text-blue-700 border-blue-400'
    }
    else {
      return 'bg-gray-200 text-gray-700 border-gray-400'
    }
  }
  return <div
    class={`flex px-5 py-2 border rounded ${variantColor(variant)}`}
  >
    {message}
  </div>
}
