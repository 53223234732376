import { useEffect } from "react";
import { RadioButton, Select } from "../../components/Inputs";
import { useDispatch, useSelector } from "react-redux";
import {
  addPackage,
  getPackagePlans,
  getPackages,
} from "../../state/actions/cameras-actions";
import { useState } from "react";
import Button from "../../components/Button";

export const PAY_METHODS = {
  cash: "Cash",
  khalti: "Khalti",
  e_sewa: "E-Sewa",
};

const NewPackage = ({ camera_id, handleClose }) => {
  const dispatch = useDispatch();

  const { profileData, subscribeLoading, packageData, plansData } = useSelector(
    (state) => ({
      profileData: state?.profile?.profileData,
      subscribeLoading: state?.cameras?.loaders?.subscribe,
      packageData: state?.cameras?.packageData,
      plansData: state?.cameras?.plans,
    })
  );

  const [packageList, setPackageList] = useState([]);
  const [pacakesNormalized, setPackagesNormalized] = useState({});
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("cash");
  const [plansList, setPlansList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [plansNormalized, setPlansNormalized] = useState({});

  useEffect(() => {
    if(camera_id){
      dispatch(getPackages(camera_id));
    }
  }, []);
console.log('camera_id',camera_id)
  useEffect(() => {
    const list = packageData?.data;
    const tempPackages = [];
    let tempNormalized = {};
    if (list?.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        tempPackages.push({
          label: `${element.name} (${element?.type})`,
          value: element.id,
        });
        tempNormalized = { ...tempNormalized, [element?.id]: element };
      }
      setPackageList(tempPackages);
      setPackagesNormalized(tempNormalized);
    }
  }, [packageData]);

  useEffect(() => {
    const list = plansData?.data;
    const tempPlans = [];
    let tempNormalized = {};

    if (list?.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        tempPlans.push({
          label: `${element.display_name}`,
          value: element.id,
        });
        tempNormalized = { ...tempNormalized, [element?.id]: element };
      }
      setPlansList(tempPlans);
      setPlansNormalized(tempNormalized);
    }
  }, [plansData]);

  useEffect(() => {
    if (selectedPackage) {
      dispatch(getPackagePlans(selectedPackage));
    }
  }, [selectedPackage]);

  const DivComponent = ({ label, value, extraClass }) => {
    return (
      <div className={`w-1/3 ${extraClass}`}>
        <p className="font-medium  text-gray-600">{label || ""}</p>
        <p className="font-semibold text-base">{value || ""}</p>
      </div>
    );
  };

  const handleSelect = (name) => (event) => {
    const { value } = event?.target;
    if (name === "package") {
      setSelectedPackage(value);
    } else if (name === "plan") {
      setSelectedPlan(value);
    }
  };

  const handleSubscribe = () => {
    const saveData = {
      package_id: selectedPackage,
      package_plan_id: selectedPlan,
      payment_gateway: "e-payment",
      payment_method: "khalti",
      qty: 1,
      subscriber_id: profileData?.id,
      created_by: profileData?.username,
      subscriber_ipcam_id: camera_id,
    };
    dispatch(addPackage({ config: saveData }));
  };

  return (
    <div className="grid gap-4 min-h-[400px]">
      <p className="font-semibold text-lg px-6 pt-4">Select Subscriptions</p>

      <div className="grid grid-cols-2 gap-x-4 px-6">
        <Select
          label={"Packages"}
          keyOptions={packageList}
          handleChange={handleSelect("package")}
          value={selectedPackage}
        />
        {selectedPackage && (
          <Select
            label={"Plans"}
            keyOptions={plansList}
            handleChange={handleSelect("plan")}
            value={selectedPlan}
          />
        )}
      </div>
      <div>
        {selectedPackage !== "" && (
          <div className="bg-gray-100 border-y-2 px-4">
            <div className="grid grid-cols-3">
              <div className={`grid gap-2 `}>
                <p className="font-medium  text-gray-600">Package</p>
                <p className="font-semibold text-base">
                  {pacakesNormalized[selectedPackage]?.name}
                </p>
              </div>

              {selectedPlan !== "" && (
                <div className={`grid gap-2  mx-auto`}>
                  <p className="font-medium  text-gray-600">Plan</p>
                  <p className="font-semibold text-base">
                    {plansNormalized[selectedPlan]?.display_name}
                  </p>
                </div>
              )}
              {selectedPlan !== "" && (
                <div className={`grid gap-2 text-right`}>
                  <p className="font-medium  text-gray-600">Total</p>
                  <p className="font-semibold text-base">
                    {`${
                      Number(plansNormalized[selectedPlan]?.package_price) +
                      Number(plansNormalized[selectedPlan]?.vat_amount)
                    } `}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className=" flex gap-x-4 justify-end px-6">
        <div>
          <Button handleClick={handleClose} variant={"error"}>
            Cancel
          </Button>
        </div>
        <div>
          <Button
            handleClick={handleSubscribe}
            isDisabled={selectedPackage === "" || selectedPlan === ""}
          >
            {subscribeLoading ? "Subscribing..." : "Subscribe"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPackage;
