import React, { useEffect } from "react";
import { generateQueryString } from "./helper";
import useScript from "./useScript";
import AppleLogo from "../../assets/image/appleLogo.png";

const AppleID = "";

const AppleLogin = ({
  clientId,
  redirectURI,
  state = "",
  render,
  designProp = {
    locale: "en_US",
  },
  responseMode = "query",
  responseType = "code",
  nonce,
  callback,
  scope,
  autoLoad = false,
  usePopup = false,
}) => {
  const [loaded] = useScript(
    `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
  );

  const onClick = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!usePopup) {
      window.location.href = `https://appleid.apple.com/auth/authorize?${generateQueryString(
        {
          response_type: responseType,
          response_mode: responseMode,
          client_id: clientId,
          redirect_uri: encodeURIComponent(redirectURI),
          state,
          nonce,
          scope: responseMode === "query" ? "" : scope,
        }
      )}`;
    } else {

      try {
        const data = await window.AppleID.auth.signIn();
        if (typeof callback === "function" && data) {
          console.log('data', data)
          callback(data);
        }
      } catch (err) {
        if (typeof callback === "function") {
          callback({ error: err });
        }
      }
    }
  };

  useEffect(() => {
    if (!usePopup) {
      if (autoLoad) {
        onClick();
      }

      if (
        typeof callback === "function" &&
        responseMode === "query" &&
        responseType === "code" &&
        window &&
        window.location
      ) {
        let match;
        const pl = /\+/g, // Regex for replacing addition symbol with a space
          search = /([^&=]+)=?([^&]*)/g,
          decode = (s) => {
            return decodeURIComponent(s.replace(pl, " "));
          },
          query = window.location.search.substring(1);

        let urlParams = {};
        while ((match = search.exec(query))) {
          urlParams[decode(match[1])] = decode(match[2]);
        }
        if (urlParams["code"]) {
          callback({
            code: urlParams["code"],
          });
        }
      }
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (usePopup && loaded) {
      window.AppleID?.auth?.init({
        clientId,
        scope,
        redirectURI: redirectURI || `https://www.apple.com`,
        state,
        nonce,
        usePopup,
      });

      // Call on auto load.
      if (autoLoad) {
        onClick();
      }
    }
    return () => { };
  }, [loaded, usePopup]);

  if (typeof render === "function") {
    return render({ onClick });
  }

  return (
    <div id="" onClick={onClick}>
      <img src={AppleLogo} className="w-6 h-6" />
    </div>
  );
};

export default AppleLogin;
